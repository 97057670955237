<template>
    <div id="examine">
        <van-tabs v-model="active" swipeable sticky >
            <van-tab v-for="(item,index) in tabs" :title="item.name"  :key="index">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh(index)">
                    <van-list
                            v-if="!isEmpty[index]"
                            v-model="loading"
                            :finished="finished[index]"
                            finished-text="别划了，到底儿啦"
                            @load="onLoad(index)"
                    >
                        <div class="examine-content-list" >
                            <div class="list-content"   v-for="(item,index) in list[index]" :key="index" @click="handleProduct(item)">
                                <img class="foods-cover" :src="item.product_cover" alt="">
                                <img class="foods-cover-failed" v-if="item.is_examine == 2" src="../assets/images/edit-fail.png" alt="">
                                <div class="commodity-conent">
                                    <p class="name">{{item.product_name}} {{item.specifications}} {{item.unit}}</p>
                                    <div class="commodity-price">
                                        <p class="price">￥{{item.original_price}}</p>
                                        <p class="operation" v-if="item.is_examine == 0">审核中</p>
                                        <p class="operation" v-if="item.is_examine == 2">重新编辑</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                    <van-empty v-else
                               :image="require(`@images/nothing.png`)"
                               description="暂无相关信息"/>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import {sellOutList} from "../api/requist";
    import {Toast} from "vant";
    export default {
        data() {
            return {
                tabs: [
                    {name: '审核中', statusId: '0'},
                    {name: '未通过', statusId: '1'},
                ],
                active: 0, //默认显示
                refreshing: false,
                finished: [false, false],
                pageSize: 20,
                pageCurrent: [0, 0,],
                list: [[], []],
                isEmpty: [false, false],
                loading: false,
            };
        },
        created() {
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            onRefresh(index) {
                this.finished[index] = false;
                this.list[index] = [];
                this.pageCurrent[index] = 0;
                this.isEmpty[index] = false;
                this.searchBtn(index);
            },
            onLoad(index) {
                this.searchBtn(index);
            },
            // 商品列表
            async searchBtn(index) {
                let _pageCurrent = this.pageCurrent[index]; //分页
                let _orderStatus = this.tabs[index].statusId; //商品状态
                let formDataInfo = new FormData();
                formDataInfo.append("merch_id", localStorage.getItem("merch_id"));
                formDataInfo.append("product_name", "");
                formDataInfo.append("pageCurrent", _pageCurrent + 1);
                formDataInfo.append("pageSize", this.pageSize);
                if (_orderStatus == 0) {
                    formDataInfo.append("is_examine", 0); //待审核
                } else if (_orderStatus == 1) {
                    formDataInfo.append("is_examine", 2);
                }
                sellOutList(formDataInfo).then((res) => {
                    // 加载数据
                    let code = res.code;
                    if (code === 200) {
                        let productList = res.data['ProductList'];
                        for (let i = 0; i < productList.length; i++) {
                            this.list[index].push(productList[i]);
                        }
                        if( this.list[index].length === 0){
                            this.isEmpty[index] = true;
                        }
                        this.refreshing = false;
                        this.loading = false;
                        if (productList.length < this.pageSize) {
                            this.finished[index] = true;
                        }
                        this.pageCurrent[index]++;
                    }else if (code === 202) {
                        // // 当暂无对应类型订单数据时，显示缺省信息
                        this.refreshing = false;
                        this.loading = false;
                        this.finished[index] = true;
                        this.isEmpty[index] = true;
                    } else {
                        return Toast(res.message);
                    }
                })
            },
            // 重新编辑
            handleProduct(item) {
                if (item.is_examine == 2) {
                    this.$router.push({
                        path: "/commodityOperate",
                        query: {
                            type: "edit",
                            id: item.id,
                        },
                    });
                }
            },
        },
    };
</script>
<style lang="scss">
    #examine {
        .van-tabs__wrap {
            padding: 0 83px;
            background: #fff;
        }

        .van-tabs__line {
            background-color: #FF6737;
        }

        .examine-content-list {
            margin: 18px 12px 0 12px;
            .list-content {
                display: flex;
                margin-bottom: 18px;
                .foods-cover {
                    width: 80px;
                    height: 80px;
                }
                .foods-cover-failed {
                    width: 80px;
                    height: 80px;
                    max-width: 80px;
                    margin-left: -80px;

                }
                .commodity-conent {
                    flex: 1;
                    margin-left: 8px;
                    .name {
                        font-size: 14px;
                        height: 40px;
                        line-height: 20px;
                        color: #303133;
                        opacity: 1;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                    .commodity-price {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 18px;
                        align-items: center;
                        .price {
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 22px;
                            color: #FF4848;
                        }
                        .operation {
                            font-size: 12px;
                            line-height: 17px;
                            color: #FF6737;
                        }
                    }
                }
            }
        }
        .van-empty {
            margin-top: 30%;
            .van-empty__image {
                width: 100%;
                height: auto;
            }
        }
    }
</style>
